import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name"> La Barbie </strong>
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>
            <p>&nbsp;&nbsp;&nbsp;</p>
            <Col>
              <h2>
               About <strong className="main-name"> Michelle </strong>
              </h2>
              <p>
                Michelle Casamayor is a Venezulen MMA fighter, professional model, and entrepreneur.
                She has a love for the confluence of femininity and toughness, challenging the conventional notion that women cannot achieve both.
                Michelle is an aspiring entrepreneur of beuty products and intends to one day give back by opening a shelter for women.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
