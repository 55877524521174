import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import mma from './photo2.png';

function MMA() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <p style={{ color: "white" }}>
        <h2 style={{ fontSize: "1.9em" }}>
        La <span className="purple"> Barbie </span> 
        </h2>
        Michelle is fighting out of Medellin, Colombia. Her fighting style is Judo and Boxing.
        </p>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={mma} className="img-fluid" alt="avatar" />
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default MMA;
