import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import model from './photo3.png';

function Model() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "1.9em" }}>
        Professional <span className="purple">  Model </span>   
        </h3>
        </p>
        <p style={{ color: "white" }}>
          Styleish and classy, Michelle is a model with a unique complexion, athletism, and beauty.
        </p>
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center">
            <img src={model} className="img-fluid" alt="avatar" />
          </Col>
        </Row>


      </Container>
    </Container>
  );
}

export default Model;
